<template>
  <div class="introduce">
    <div class="topImg">
      <img src="./img/pointRule.png" alt="" />
    </div>
    <div class="body">
      <div class="body-center">
        <div class="body-c">
          <div class="item">
            <div class="item-t">1. 用户注册：</div>
            <div class="item-b">获得5积分，完成注册即可。</div>
          </div>
          <div class="item">
            <div class="item-t">2. 用户完善资料：</div>
            <div class="item-b">
              获取10积分，必须填写完整房号，并审核通过才能获得，且只能获得一次。
            </div>
          </div>
          <div class="item">
            <div class="item-t">3. 每日签到：</div>
            <div class="item-b">
              签到获取1积分，连续签到三天，第三天获得2积分，连续签到五天，第五天获得4积分，连续签到七天，第七天获得7个积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">
              4. 参加活动（含幸福学堂、和丰大讲堂等学习课程）：
            </div>
            <div class="item-b">
              用户报名参与活动后可以获得5积分（扫描活动签到二维码获得）。
            </div>
          </div>
          <div class="item">
            <div class="item-t">5. 未来心愿：</div>
            <div class="item-b">
              用户提交心愿信息经小和审核后，可获取2积分（每人每日仅第一次发布可以获得积分哦）。
            </div>
          </div>
          <div class="item">
            <div class="item-t">6. 社区共建：</div>
            <div class="item-b">
              用户提交社区共建意见经小和审核后，可获取1-5积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">7. 公益捐赠：</div>
            <div class="item-b">
              用户公益捐赠物品后，根据物品价值，可获取5-100积分。
            </div>
          </div>
          <!-- <div class="item">
            <div class="item-t">8. 运动打卡：</div>
            <div class="item-b">
              用户上传运动软件打卡照片经小和审核后，可获取2积分，每日仅可上传一次，审核失败允许重新提交，审核通过后，当天无法再发起提交。
            </div>
          </div> -->
          <div class="item">
            <div class="item-t">8. 申请和·伙人：</div>
            <div class="item-b">
              提交和·伙人申请，运营官小和审核通过后可获取30积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">9. 和·伙人举办活动：</div>
            <div class="item-b">
              和·伙人提交举办活动申请，运营官小和审核通过并成功组建活动后，可获取10-50积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">10. 小和超话区：</div>
            <div class="item-b">
              小和每个超话结束之前，评论区点赞前十名用户可获取2积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">11. 添加运营官小和微信：</div>
            <div class="item-b">
              用户添加运营官微信，上传与运营官小和聊天截图可获取2积分。
            </div>
          </div>
          <div class="item">
            <div class="item-t">12. 特殊人群帮扶：</div>
            <div class="item-b">
              留守、困境儿童和空巢、留守、失能、重残、计划生育特殊家庭老年人等均可每日额外领取3积分，特殊人群参与社区活动可额外获得10积分，社区居民帮扶特殊人群，社工核实后可获得30积分。
            </div>
          </div>
        </div>
        <div class="body-b">
          <div class="title"><span>注意事项</span></div>
          <p>积分礼品数量有限，先领取先得哦~</p>
          <p><b>领取方式：</b>凭积分至运营官小和处核销领取</p>
          <p><b>领取地点：</b>和丰居委会</p>
          <p><b>领取时间：</b>周一至周五9:00-18:00，周末及法定节假日除外</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.introduce {
  min-height: 100vh;
  padding: 0 0 68px;
  background: rgba(255, 207, 46, 1);
  .topImg {
    height: 600px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    margin-top: -222px;
    z-index: 99;
    position: relative;
    .body-center {
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 54px 4px;
      .body-c {
        padding-bottom: 40px;
      }
      .item {
        margin-bottom: 48px;
        font-size: 32px;
        font-weight: 400;
        color: #646668;
        line-height: 44px;
        .item-t {
          font-size: 32px;
          font-weight: 700;
          color: #323334;
          line-height: 44px;
          margin-bottom: 8px;
        }
      }
      .title {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #2992fd;
        margin-bottom: 22px;
        line-height: 44px;
        span {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            left: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            right: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }
      .body-b {
        padding-bottom: 66px;
        p {
          font-size: 28px;
          font-weight: 400;
          color: #646668;
          line-height: 56px;
          b {
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }
}
</style>
